<template>
  <div class="w-100 h-100 position-fixed d-flex align-items-center justify-content-center"
    style='background:rgba(255,255,255,0.6);z-index:99999;top:0;left:0' v-if='show'>
    <!-- v-if='show' -->
    <div class='d-flex flex-column'>
      <div class='d-block text-center position-relative'>
        <div>
          <img src="@/assets/whoscare-loading.svg" alt="" style='max-width:180px;'>
        </div>
        <div class="position-absolute" style="left:12px;top:10px;">
          <!-- <img src="@/img/LOGO_sm.png" class=" mr-2" alt
            style="max-height: 45px;" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "loadingpage",
    data() {
      return {}
    },
    props: {
      show: {
        type: Boolean,
        default: true
      }
    }
  }
</script>
<style lang="scss">
  @import '../style/_variables';

  @keyframes myfirst {
    0% {
      background: map-get($theme-colors, danger);
    }

    25% {
      background: map-get($theme-colors, warning);

    }

    50% {
      background: $magee-blue;

    }

    75% {
      background: map-get($theme-colors, success);

    }

    100% {
      background: map-get($theme-colors, danger);
    }
  }

  @-webkit-keyframes myfirst {
    0% {
      background: map-get($theme-colors, danger);
    }

    25% {
      background: map-get($theme-colors, warning);

    }

    50% {
      background: $magee-blue;

    }

    75% {
      background: map-get($theme-colors, success);

    }

    100% {
      background: map-get($theme-colors, danger);
    }
  }

  .loading {
    .box {
      animation: myfirst 5s infinite;
      -webkit-animation: myfirst 5s infinite;

    }
  }
</style>