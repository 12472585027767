import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './style/index.scss';
import {
  BootstrapVue,
  BIcon,
  BIconArrowUp,
  BIconArrowDown
} from 'bootstrap-vue';
import {
  BootstrapVueIcons
} from 'bootstrap-vue';
import "./plugins/fontawesome";
import VCalendar from 'v-calendar';
import VueCurrencyFilter from "vue-currency-filter";
import VueSignaturePad from 'vue-signature-pad';
// import FullCalendar from '@/assets/fullcalendar/index.js';
import VueUploadComponent from 'vue-upload-component';
import VueMoment from "vue-moment";
import moment from "moment";
import "moment/locale/zh-tw";
// moment.locale('fr');
Vue.component('file-upload', VueUploadComponent)
moment.locale('zh-tw')
Vue.config.productionTip = false
Vue.use(VueSignaturePad);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('BIcon', BIcon);
Vue.component('BIconArrowUp', BIconArrowUp);
Vue.component('BIconArrowDown', BIconArrowDown);
Vue.use(VCalendar, {
  componentPrefix: 'v',
});
Vue.use(VueCurrencyFilter, {
  symbol: "$",
  thousandsSeparator: ",",
  fractionCount: 0,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true
});

Vue.use(VueMoment, {
  moment
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')