import Vue from 'vue'
import VueRouter from 'vue-router'

import store from "@/store"
import {
  _
} from 'core-js';
const getPages = (name, path = "views") => {
  return () => {
    return import(`@/${path}/${name}.vue`);
  }
};
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    component: getPages("Home"),
    
  },
  {
    path: '/Portal',
    name: 'Portal',
    component: getPages("Portal"),
    meta: {
      checkUser: true,
    }
  },
  {
    path: '/waitList',
    name: 'waitList',
    component: getPages("waitList", "views/todo"),
    meta: {
      checkUser: true,
      tomanual: "device"
    }
  },
  {
    path: '/caseManage',
    name: 'caseManage',
    component: getPages("caseManage", "views/todo"),
    meta: {
      checkUser: true,
      tomanual: "case"
    }
  },
  {
    path: '/closedList',
    name: 'closedList',
    component: getPages("closedList", "views/todo"),
    meta: {
      checkUser: true,
      tomanual: "record"
    }
  },
  {
    path: '/caseList',
    name: 'caseList',
    component: getPages("caseList", "views/todo"),
    meta: {
      checkUser: true,
      tomanual: "register"
    }
  },
  {
    path: '/morefunc',
    name: 'morefunc',
    component: getPages("morefunc", "views/todo"),
    meta: {
      checkUser: true,
      tomanual: "more"
    }
  },
  {
    path: '/online',
    name: 'online',
    component: getPages("online", "views/todo"),
    meta: {
      checkUser: true,
      tomanual: "partner"
    }
  },
  {
    path: '/todoInfo/:pro',
    name: 'todoInfo',
    component: getPages("todoInfo", "views/todo"),
    meta: {
      checkUser: true,
    }
  },
  {
    path: '/caseDetail/:pro',
    name: 'caseDetail',
    component: getPages("caseDetail", "views/todo"),
    meta: {
      checkUser: true,
      tomanual: "case"
    }
  },
  {
    path: '/deviceDetail/:pro',
    name: 'deviceDetail',
    component: getPages("FDevice", "views/todo"),
    meta: {
      checkUser: true,
      tomanual:"device"
    }
  },
  {
    path: '/FDevice',
    name: 'FDevice',
    component: getPages("FDevice", "views/todo"),
    meta: {
      checkUser: true,
      tomanual:"device"
    }
  },
  {
    path: '/Alert',
    name: 'Alert',
    component: getPages("Alert", "views/todo"),
    meta: {
      checkUser: true,
    }
  },
  {
    path: '/deviceInfo/:dName',
    name: 'deviceInfo',
    component: getPages("deviceInfo", "views/todo/device"),
    meta: {
      checkUser: true,
      tomanual:"device"
    }
  },
  {
    path: '/partnerInfo',
    name: 'partnerInfo',
    component: getPages("partnerInfo", "views/todo/partner"),
    meta: {
      checkUser: true,
      tomanual: "partner"
    }
  },
  {
    path: '/partnerInfo/:uid',
    name: 'updatepartnerInfo',
    component: getPages("partnerInfo", "views/todo/partner"),
    meta: {
      checkUser: true,
      tomanual: "partner"
    }
  },
  {
    path: '/support/Legal',
    name: 'LegalInfo',
    component: getPages("Legal", "views/todo/support"),
    meta: {
      checkUser: true
    },
  },
  {
    path: '/support/Privacy',
    name: 'Privacy',
    component: getPages("Privacy", "views/todo/support"),
    meta: {
      checkUser: true
    },
  }, {
    path: '/support/manual',
    name: 'manual',
    component: getPages("manual", "views/todo/support"),
    meta: {
      checkUser: true
    },
  }
  


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let checkUser = false;
  _.each(to.matched, (item, keys) => {
    if (item.meta.checkUser) {
      checkUser = true;
    }
  });
  if (checkUser) {
    store.dispatch("member/getmember").then((data) => {
      if (!store.state.member.login) {
        next({
          name: "Home"
        })
      } else {
        next();
      }
    }).catch((data) => {
      store.dispatch("Public/showToast", {
        title: "警告",
        method: "danger",
        text: "您的帳號認證已過期，請重新嘗試！"
      });
      next({
        name: "Home"
      })
    })
  } else {
    store.dispatch("member/getmember").then((data) => {
      if (store.state.member.login) {
        next({
          name: "Portal"
        });
      } else {
        next();
      }
    }).catch((data) => {
      next();
    })
  }
});

export default router