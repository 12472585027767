import axios from 'axios';
import store from '@/store';
const cancelrequest = {};
const listspinner = {};
export default {
    httpctrl(method, url, data, config = {}, cancelname = "", spinner = true) {
        var CancelToken = axios.CancelToken;
        if (localStorage.token) {
            config = {
                'headers': {
                    'Authorization': "Bearer " + localStorage.token,
                }
            }
        }
        const responsedata = (method == "get") ? {
            params: data
        } : {
            data: data
        };
        if (cancelname && cancelrequest[cancelname]) {
            cancelrequest[cancelname]();
            delete listspinner[url];
            if (Object.keys(listspinner).length == 0) {
                store.dispatch("Public/openLoading", url)
            }
        }

        if (spinner) {
            store.dispatch("Public/openLoading", url)
            listspinner[url] = 1;
        };
        const ajax = axios.request({
            baseURL: process.env.VUE_APP_APILOCAL,
            url: url,
            method: method,
            cancelToken: new CancelToken((c) => {
                (cancelname) ? cancelrequest[cancelname] = c: "";
            }),
            ...responsedata,
            ...config,

        });
        ajax.then((data) => {
            if (spinner) {
                delete listspinner[url];
                if (Object.keys(listspinner).length == 0) {
                    store.dispatch("Public/closeLoading", url)
                }
            };
        }).catch((error) => {
            if (error.response.status == 401 || error.response.data.error == "Unauthenticated") {
                localStorage["member"] = "";
                localStorage["token"] = "";
                // location.href = "/";
            }
            delete listspinner[url];
            if (Object.keys(listspinner).length == 0) {
                store.dispatch("Public/closeLoading", url)
            }
        });
        return ajax;
    },
    post(url, data, config, cancelname = "", spinner = true) {
        return this.httpctrl("post", url, data, config, cancelname, spinner);
    },
    get(url, data, config, cancelname = "", spinner = true) {
        return this.httpctrl("get", url, data, config, cancelname, spinner);
    }
}