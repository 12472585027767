import sup from './support';

export default {
    login(account, password, company, method, vehicle, companycheck) {
        return sup.post("login", {
            account: account,
            password: password,
            company: company,
            method: method,
            vehicle: vehicle,
            companycheck: companycheck
            
        });
    },
    getmember() {
        return sup.get("Login/getmember", {}, {}, "", false);
    },
    getLevel(tablelist){
        return sup.post("getLevel",tablelist)
    }
}