import Vue from 'vue'
import Vuex from 'vuex'
import member from './modules/member';
import caseSort from './modules/caseSort';
import Public from './modules/Public';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    member: member,
    caseSort: caseSort,
    Public: Public
  }
})
