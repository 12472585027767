import caseSort from "@/service/caseSort";
import _ from "lodash";
import moment from "moment";

export default {
	namespaced: true,
	state: {
		GetCaseStatus: {
			acceptFalse: [],
			acceptTrue: [],
		},
		GetAllCase: [],
		GetUpdateData: [],
		GetSetUpdate: [],
		GetScaseStatus: {
			emergency: [],
			assign: [],
			normal: [],
			working: [],
			worked: [],
			list: [],
			success: [],
		},
		alert: {
			accecpt: 0,
			noaccecpt: 0,
		},
		GetInsertData: [],
		GetFieldDefault: [],
		GetAlert: [],
	},
	actions: {
		noAccept({ commit }, data) {
			// caseSort.searchCount(data.date).then((data) => {
			//     commit("setAlertNoAccept", data.data.count);
			// });
		},
		CaseStatus({ commit }, data) {
			const result = caseSort.AllCase(data.orderBy, data.page, data.pagenumber, data.search);
			result.then((data) => {
				const acceptTemp = _.groupBy(data.data.data, "accept");
				let acceptData = {
					acceptTrue: acceptTemp[1] || [],
					acceptFalse: acceptTemp[0] || [],
				};
				commit("SetCaseStatus", acceptData);
			});
			return result;
		},
		AllCase({ commit }, data) {
			const result = caseSort.AllCase(data.orderBy, data.page, data.pagenumber, data.search);
			result.then((data) => {
				commit("SetAllCase", data.data.data);
			});
			return result;
		},
		insertData({ commit }, data) {
			const result = caseSort.setInsert(data.table, data.data);
			result
				.then((data) => {
					if (data.data[0]) {
						commit("SetInsertData", data.data[0]);
						// resolve(result)
					} else {
						// reject(result);
					}
					return data.data;
				})
				.catch((error) => {
					return error;
				});
			// console.log(result);

			return result;
			return new Promise((resolve, reject) => {});
		},
		getfielddefault({ commit }, data) {
			const result = caseSort.getfielddefault(data.table, data.where, data.field);
			result.then((data) => {
				commit("SetFieldDefault", data.data);
			});
			return result;
		},
		getTable({ commit }, data) {
			const result = caseSort.getTable(data.table, data.orderBy, data.page, data.pagenumber, data.search, data.join, data.field, data.spinner, data.searchmethod);
			result.then((data) => {
				commit("SetAllCase", data.data.data);
			});
			return result;
		},
		getMultiTable({}, data) {
			const result = caseSort.getMultiTable(data);
			return result;
		},
		getUpdateData({ commit }, data) {
			const result = caseSort.getUpdateData(data.table, data.id);
			result.then((data) => {
				commit("SetGetUpdateData", data.data.data);
			});
		},
		async setUpdate({ commit }, data) {
			const result = await caseSort
				.setUpdate(data.table, data.id, data.data)
				.then((data) => {
					return data.data;
				})
				.catch((error) => {
					return error;
				});
			return new Promise((resolve, reject) => {
				if (result[0]) {
					commit("SetInsertData", result);
					resolve(result);
				} else {
					reject(result);
				}
			});
		},
		deleteData({ commit }, data) {
			const result = caseSort.deleteData(data.table, data.id);
			return result;
		},
		async getScaseStatus({ commit }, data) {
			//addfield
			const addfield = data.addfield || [];
			const searchdata = {};
			data.accept != undefined ? (searchdata.accept = data.accept) : "";
			if (data.search) {
				_.each(data.search, (item, keys) => {
					searchdata[keys] = item;
				});
			}
			const assign_search = {
				sametext: {
					caseReturn: "!1",
					// accept: "1"
					// complete_id:"!3"
				},
			};
			if (data.match) {
				assign_search["rpl_date"] = data.match.date;
			} else {
				assign_search["rpl_date"] = "";
			}
			//  return search;
			// const joindata = data.join || {};
			const scaseOrder = data.orderBy || {
				name: "scase.createtime",
				order: "desc",
			};
			const scase = await caseSort
				.getTable(
					"scase",
					scaseOrder,
					0,
					"all",
					searchdata,
					{
						leftjoin: {
							scaseAPL: {
								mainfield: "scase.uid",
								subfield: "belong_scase",
								where: {},
								select: "scaseAPL.uid as APLuid,scaseAPL.aginwork, scaseAPL.c_cancel as APLc_cancel",
							},
							assign: {
								mainfield: "scaseAPL.uid",
								subfield: "belong_scaseAPL",
								where: assign_search,
								select: "assign.complete_id as asscomplete_id , assign.uid as ass_uid , assign.accept as  ass_accept , assign.rpl_date as ass_rpl_date , assign.rpl_time as ass_rpl_time,assign.emergency as ass_emergency",
							},
							worked: {
								mainfield: "scaseAPL.uid",
								subfield: "belong_scaseAPL",
								where: {},
								select: "`worked`.uid as worked_uid ,worked.ended as worked_ended ",
							},
							F_Device: {
								mainfield: "scase.product_id",
								subfield: "uid",
								where: {},
								select: "`F_Device`.warrantyDate as TwarrantyDate  ",
							},
							systemParame: {
								mainfield: "F_Device.kid_sys",
								subfield: "uid",
								where: {
									sametext: {
										func: "SCASEKIND",
									},
								},
								select: "`systemParame`.values as SY_values  ",
							},
						},
					},
					[
						"uid",
						"id",
						"BrandSeries",
						"TproClass",
						"areaName",
						"belong_client",
						"belong_proClass",
						"brand",
						"case_sts_id",
						"casestatus",
						"case_cnt",
						"kid",
						"kid_sys",
						"online_id",
						"product_cname",
						"product_id",
						"ques_pk",
						"source",
						"sourceUid",
						"phoneno",
						"do_date",
						"do_time",
						"code",
						"caseCode",
						"IOTCode",
						"IOT_gateway",
						"IOT_temp",
						"c_cancel",
						"emergency",
						"createtime",
						"caseover",
						"belong_clientPartner",
						"remark",
						"product_name",
						"lastCompleteDate",
						"c_cancelRemark",
						"finishRemark",
						// "rpl_date",
						// "rpl_time",
						// "range",
						...addfield,
					],
					true,
					{},
					"SCaseList"
				)
				.then((data) => {
					return data.data;
				});

			// 20090700010E0  SC20090700010L1
			let assgindata = {
				data: [],
				groupBy: {},
			};
			let proRepairer = {
				data: [],
			};
			const scaseAPLuid = _.filter(
				_.map(scase.data, (item, keys) => {
					return item && item.APLuid ? item.APLuid : "";
				})
			);
			if (scaseAPLuid.length > 0) {
				assgindata = await caseSort
					.getTable(
						"assign",
						{
							name: "createtime",
							order: "desc",
						},
						0,
						"all",
						{
							wherein: {
								belong_scaseAPL: scaseAPLuid,
							},
							sametext: {
								belong_scaseAPL: "!",
							},
						},
						{},
						[],
						true,
						{},
						"AssignListsForscase"
					)
					.then((data) => {
						return {
							data: data.data.data,
							groupBy: _.groupBy(data.data.data, "belong_scaseAPL"),
						};
					});
				// console.log(assgindata);
			}
			if (
				_.filter(scase.data, (item) => {
					return item.sourceUid;
				}).length > 0
			) {
				proRepairer = await caseSort
					.getTable(
						"clientPartner.client",
						{
							name: "createtime",
							order: "desc",
						},
						0,
						"all",
						{
							wherein: {
								uid: _.filter(
									_.map(scase.data, (item, keys) => {
										return item && item.belong_clientPartner ? item.belong_clientPartner : "";
									})
								),
							},
						},
						{},
						["name", "uid", "id", "shortname", "phone", "telphone"],
						true,
						{},
						"clientPartnerForscase"
					)
					.then((data) => {
						return data.data;
					});
			}
			const assgingroup = assgindata.groupBy;

			const Repairergroup = _.groupBy(proRepairer.data, "uid");
			console.log(assgingroup, Repairergroup, scase);
			//=============
			const success = [];
			const emergency = [];
			const assigndata = [];
			const normal = [];
			const working = [];
			const worked = [];
			const cancel = [];
			scase.data = _.map(scase.data, (item, keys) => {
				const assigcount = assgingroup[item.APLuid] || [];
				const thRepairergroup = Repairergroup[item.belong_clientPartner] ? Repairergroup[item.belong_clientPartner][0] : {};
				let assignd = assigcount.length > 0 ? _.find(assigcount, { uid: item.ass_uid }) : {};
				const listindex = _.findIndex(assigcount, {
					uid: item.ass_uid,
				});
				// assignd = assignd.length > 0 ? assignd[0] : {};
				item.assigndata = assignd;
				item.isAginAssign = assigcount.length - 1 > listindex;
				item.Repairer = thRepairergroup;
				const ttimefield = !!item.ass_uid ? "rpl_time" : "do_time";
				const tdatefield = !!item.ass_uid ? "rpl_date" : "do_date";
				// const temergencyfield = !!item.ass_uid ? "emergency" : "emergency";
				_.each(["actCheckIn", "actCheckout", "complete_id", "accept"], (it) => {
					item[it] = "";
					if (assignd) {
						item[it] = assignd[it] || "";
					}
				});
				item.noassign = assignd ? !assignd.accept : 0;
				item.firstassign = data.match ? item.do_date == data.match.date : true;
				item.c_cancel = item.APLc_cancel || item.c_cancel;
				item.aginwork = item.aginwork || 0;
				item.ddate = assignd ? assignd[tdatefield] : item.do_date;
				item.dtime = assignd ? assignd[ttimefield] : item.do_time;
				item.demergency = assignd ? assignd.emergency : item.emergency;
				if (item.c_cancel == 1) {
					cancel.push(item);
				} else if (item.asscomplete_id == 1 || item.asscomplete_id == 2 || item.caseover == 1) {
					success.push(item);
				} else {
					if (item.emergency == 1) {
						emergency.push(item);
					} else if (item.do_time && item.emergency == 0) {
						assigndata.push(item);
					} else if (!item.do_time && item.emergency == 0) {
						normal.push(item);
					}
				}
				return item;
			});
			let status = {
				success: success,
				emergency: emergency,
				assign: assigndata,
				normal: normal,
				working: working,
				worked: worked,
				cancel: cancel,
				list: scase.data,
			};
			//  console.log(status);
			commit("setAlertAccept", scase.count);
			commit("SetScaseStatus", status);
			return scase;
		},
		AlertMessage({ commit }, data) {
			const result = caseSort.AlertMessage(data.page, data.pagenumber, data.method);
			result.then((data) => {
				commit("SetAlert", data.data);
			});
			return result;
		},
		changeBeforeData({ commit }, { setmodel, value, field, func }) {
			const result = caseSort.changeBeforeData(setmodel, value, field, func);
			result.then((data) => {
				// commit("SetAlert", data.data);
			});
			return result;
		},
	},
	mutations: {
		setAlertNoAccept(state, data = 0) {
			state.alert.noaccecpt = data;
		},
		setAlertAccept(state, data = 0) {
			state.alert.accecpt = data;
		},
		SetInsertData(state, data) {
			state.GetInsertData = data;
		},
		SetCaseStatus(state, data) {
			state.GetCaseStatus = data;
		},
		SetAllCase(state, data) {
			state.GetAllCase = data;
		},
		SetFieldDefault(state, data) {
			state.GetFieldDefault = data;
		},
		SetGetUpdateData(state, data) {
			state.GetUpdateData = data;
		},
		SetUpdate(state, data) {
			state.GetSetUpdate = data;
		},
		SetScaseStatus(state, data) {
			state.GetScaseStatus = data;
		},
		SetAlert(state, data) {
			state.GetAlert = data;
		},
	},
};
