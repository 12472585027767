import sup from "./support";
import store from "@/store";
import _ from "lodash";
export default {
	async refreshdata(collect) {
		const data = new FormData();
		const Quesuelist = [];
		_.each(collect, (e, i) => {
			if (i == "file") {
				_.each(e, async (ee, ii) => {
					if (ee.files && ee.files[0] && ee.files[0].file) {
						const Pmise = new Promise((resolve, reject) => {
							console.log(ee.files[0].file);
							const reader = new FileReader();
							reader.readAsDataURL(ee.files[0].file);
							const fileName = ee.files[0].file.name;
							reader.onload = (e) => {
								const img = new Image();
								img.src = e.target.result;
								img.onload = (e) => {
									const canvas = document.createElement("canvas");
									let MAX_WIDTH = 800,
										MAX_HEIGHT = 600,
										width = img.width,
										height = img.height;
									if (width > height) {
										if (width > MAX_WIDTH) {
											height *= MAX_WIDTH / width;
											width = MAX_WIDTH;
										}
									} else {
										if (height > MAX_HEIGHT) {
											width *= MAX_HEIGHT / height;
											height = MAX_HEIGHT;
										}
									}
									canvas.width = width;
									canvas.height = height;
									const ctx = canvas.getContext("2d");
									ctx.drawImage(img, 0, 0, width, height);

									ctx.canvas.toBlob(
										(blob) => {
											const file = new File([blob], fileName, {
												type: "image/jpeg",
												lastModified: Date.now(),
											});
											resolve({ field: "file[" + ii + "]", file: file });
										},
										"image/jpeg",
										1
									);
								};
							};
						});
						Quesuelist.push(Pmise);
					}
				});
			} else {
				data.append(i, e);
			}
		});
		const Queue = Promise.all(Quesuelist);
		await Queue.then((file) => {
			_.each(file, (item, keys) => {
				data.append(item["field"], item["file"]);
			});
		});
		return data;
	},
	AllCase(
		orderBy = {
			name: "createtime",
			order: "desc",
		},
		page = 1,
		pagenumber = 20,
		search = {}
	) {
		return sup.post("Public/getData/assign", {
			table: "assign",
			addfield: "",
			orderBy: orderBy,
			page: page,
			pagenumber: pagenumber,
			search: search,
			searchmethod: "",
			searchtype: 0,
		});
	},
	searchCount(date) {
		return sup.post("Public/getData/assign", {
			table: "scaseAPL",
			addfield: "",
			orderBy: {},
			page: 0,
			pagenumber: 1,
			search: {
				whererawin: {
					uid: "select belong_scaseAPL from `assign` where accept='0' and `employee_id` = '" + store.state.member.Login.otherUid + "' and rpl_date='" + date + "'",
				},
			},
			searchmethod: "",
			searchtype: 0,
		});
	},
	getfielddefault(table, where = {}, field) {
		return sup.post("Public/getfielddefault", {
			table: table,
			where: where,
			field: field,
		});
	},
	getTable(
		table,
		orderBy = {
			name: "createtime",
			order: "desc",
		},
		page = 1,
		pagenumber = 20,
		search = {},
		join = {},
		field,
		spinner = true,
		searchmethod = {},
		cencalname = null
	) {
		return sup.post(
			"Public/getData/" + table,
			{
				table: table,
				addfield: "",
				orderBy: orderBy,
				page: page,
				pagenumber: pagenumber,
				search: search,
				searchmethod: searchmethod,
				searchtype: 0,
				join: join,
				field: field,
			},
			{},
			cencalname,
			spinner
		);
	},
	getUpdateData(table, id) {
		return sup.get("Public/getupdatedata", {
			table: table,
			id: id,
		});
	},
	async setUpdate(table, id, data, isId = true) {
		data.table = table;
		isId ? (data.id = id) : (data.uid = id);
		const Formdata = await this.refreshdata(data);

		return sup.post("Public/updatedata", Formdata, {
			headers: {
				"Content-Type": undefined,
			},
		});
	},
	deleteData(table, id) {
		return sup.post("Public/deletedata", {
			table: table,
			id: id,
		});
	},
	async setInsert(table, data) {
		data.table = table;
		const Formdata = await this.refreshdata(data);
		return sup.post("Public/insertdata", Formdata, {
			headers: {
				"Content-Type": undefined,
			},
		});
	},
	saveWorked(maindata, sub) {
		return sup.post("IOT/saveWorked", {
			maindata: maindata,
			component: sub,
		});
	},
	AlertMessage(page, pagenumber, method) {
		// return new Promise((resolve )=>{
		//     console.log("isAlerted");
		//     resolve({state:true,data:[]});
		// });
		return sup.get(
			"Public/AlertMessage",
			{
				page: page,
				pagenumber: pagenumber,
				method: method,
			},
			false,
			null,
			false
		);
	},
	changeBeforeData(setmodel, value, field, func) {
		return sup.post("Public/changeBeforeData", {
			allsemodel: setmodel,
			data: value,
			field: field,
			func: func,
		});
	},
	getMultiTable(temp,spanner,cancel) {
        return sup.post("Public/getMultiDataObject", {
            data: temp
        }, {}, cancel,spanner);
    },
};
