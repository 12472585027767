import mem from "@/service/member";
import _ from "lodash";

export default {
	namespaced: true,
	state: {
		login: false,
		name: "",
		Login: {
			Rholiday: [],
			otherinfo: {},
			company: "",
			isLogin: false,
			account: "",
			api_token: "",
			email: "",
			homephone: "",
			id: "",
			location: "",
			name: "",
			open: "",
			phone: "",
			telphone: "",
			postelcode: "",
			otherUid: "",
			company: "",
			uid: "",
			loginSunt: false,
			isSubCompany: false,
		},
		infomation: {
			sourceUid: {
				field: "linkname.sourceUid",
				value: "",
			},
			login_user: {
				field: "name",
				value: "",
			},
		},
	},
	getters: {
		getcompany(state, getters, $rootState, $rootGetters) {
			return $rootGetters["Public/getname"](state.infomation.sourceUid.value);
		},
	},
	actions: {
		login({ commit }, data) {
			const result = mem.login(data.account, data.password, data.company, data.method, data.vehicle, data.companycheck);
			return result;
		},
		async getmemberinfomation({ dispatch, commit, getters, rootState }) {
			// const memberinfo = await dispatch("getmember").then(data => {
			//     console.log(data);
			//     return data.data;
			// })
			// console.log(memberinfo);
			// return;
			const memberinfo = await dispatch("getmember").then((data) => data.data);
			const getMember = {
				addfield: "",
				orderBy: {},
				page: 0,
				pagenumber: "all",
				search: {
					sourceUid: memberinfo.company,
					uid: memberinfo.otherUid,
				},
				table: "F_Member.proClient",
				field: ["name", "updatatime", "sourceUid", "sourceTable", "method"],
			};
			const infomation = await dispatch("caseSort/getTable", getMember, {
				root: true,
			}).then((data) => {
				return data.data.data[0];
			});

			commit("infomation", infomation);
		},
		getmember({ commit }) {
			const result = mem.getmember();
			result
				.then((data) => {
					commit("setmember", {
						Login: true,
						data: data.data,
					});
				})
				.catch(() => {});
			return result;
		},
		getLevel({},table){
			return mem.getLevel(
				{
					search:{table:table}
				}
			);
		}
	},
	mutations: {
		setmember(state, data) {
			_.each(state.Login, (item, keys) => {
				if (keys != "isLogin") {
					if (keys == "uid") {
						state.Login[keys] = data.data["otherUid"];
					} else {
						state.Login[keys] = data.data[keys];
					}
				}
			});
			state.login = true;
		},
		infomation(state, data) {
			_.each(state.infomation, (item, keys) => {
				const value = _.reduce(
					item.field.split("."),
					(cur, field, fkey) => {
						return cur[field];
					},
					data
				);
				state.infomation[keys].value = value;
			});
		},
	},
};
