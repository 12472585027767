<template>
	<div id="app" class="bg-gradient-light d-flex flex-column" ref="app">
		<div v-if="isalertshow && $store.state.member.login" class="position-fixed w-100 h-100 d-flex align-items-start" style="background: rgba(0, 0, 0, 0.5); z-index: 1200;">
			<div class="text-left bg-white border shadow mx-auto" style="top: 100px; min-width: 16rem; max-width: 16rem; margin-top: 20%;">
				<div class="position-relative" style="">
					<div class="font-weight-bolder bg-info text-white m-0 d-flex justify-content-start align-items-center p-0">
						<h5 class="m-0 p-1">通知</h5>
						<div class="ml-auto border-left cur-print m-0 p-0 d-flex align-items-center justify-content-center" @click="openalert" style="font-size: 150%; width: 40px; height: 100%;">
							<b>
								<b-icon icon="x"></b-icon>
							</b>
						</div>
					</div>
					<ul class="mb-0 pl-0 bg-white">
						<li class="list-unstyled" v-for="(item, index) in status.newalert" :key="'NA__' + index">
							<div class="w-100 d-flex align-items flex-column py-2 clearfix border pointer hv-alert" style="background-color: aliceblue;">
								<div class="w-100 pl-3">{{ item.responseTablename }}</div>
								<a class="w-100 pl-3 pointer text-info" @click="gotocase(item)">{{ item.caseCode }}</a>
							</div>
						</li>
						<!-- ============================================== -->
						<li class="list-unstyled" v-for="(item, index) in status.unread" :key="'UR__' + index">
							<div class="w-100 d-flex align-items flex-column py-2 clearfix border pointer hv-alert" style="background-color: aliceblue;">
								<div class="w-100 pl-3">{{ item.responseTablename }}</div>
								<a class="w-100 pl-3 pointer text-info" @click="gotocase(item)">{{ item.caseCode }}</a>
							</div>
						</li>
						<!-- ============================================== -->
					</ul>
					<div class="w-100 p-2 text-center" style="background-color: #eee; letter-spacing: 2px;">
						<a class="font-weight-bolder text-primary pointer" @click="goAllAlert">顯示所有通知</a>
					</div>
				</div>
			</div>
		</div>
		<b-navbar toggleable="true" variant="light" id="mainbody" class="shadow-sm position-fixed justify-content-between d-flex container py-2" style="top: 0; z-index: 99; height: 53px; max-width: 100%;">
			<div v-if="$store.state.member.login" class="border-right mr-1 pr-1">
				<a class="text-dark pointer" @click="prev">
					<b-icon-chevron-left />
				</a>
			</div>
			<!-- <router-link :to="{ name: 'Portal' }">
				<img src="@/assets/whoscare-bc.png" width="160px" alt="" />
			</router-link> -->
			<div class="ml-auto d-flex flex-fill justify-content-around">
				<b-navbar-nav v-if="$store.state.member.login">
					<b-nav-item class="w-100" @click="signout()">
						<b-icon icon="person-circle" />
					</b-nav-item>
				</b-navbar-nav>
				<b-navbar-nav v-if="$store.state.member.login">
					<b-nav-item class="w-100" @click="gomanual()">
						<b-icon icon="book-half" />
					</b-nav-item>
				</b-navbar-nav>
				<b-navbar-nav v-if="$store.state.member.login">
					<b-nav-item class="w-100" @click="openalert()">
						<b-icon icon="bell-fill" />
						<span class="px-1 text-white rounded-circle font-7-pa position-absolute" style="background: Tomato; right: 1.8em; top: 0.6rem;">{{ alertcount }}</span>
					</b-nav-item>
				</b-navbar-nav>
			</div>
		</b-navbar>
		<div class="bg-white mx-auto shadow-sm flex-fill w-100" style="margin-top: 53px; margin-bottom: 53px;">
			<!--  -->
			<loading :show="openLoading"></loading>
			<router-view class="pt-2" />
		</div>
		<div id="footer" class="fixed-bottom" v-if="$store.state.member.login" style="height: 53px;">
			<b-navbar class="shadow-sm p-0">
				<b-navbar-nav class="w-100" align="center" :fill="true">
					<b-nav-item link-classes="btn btn-light border rounded-0 py-2" :class="{ active: $route.name == i.to.name }" :to="i.to" v-for="(i, index) in getbottomfeaturelist" :key="'AP__' + index" exact>
						<div class="d-flex align-items-center flex-column">
							<font-awesome-icon :icon="['fas', i.icon]" class="h5 mb-0" />
							<span class="font-7-pa">{{ i.shortname }}</span>
						</div>
					</b-nav-item>
				</b-navbar-nav>
			</b-navbar>
		</div>
	</div>
</template>

<script>
	import store from "@/store";
	import _ from "lodash";
	import caseSort from "@/service/caseSort";
	import loading from "@/components/loadingpage";
	import moment from "moment";
	export default {
		data() {
			return {
				menualtoHash: {
					manual: "",
					waitList: "device",
					FDevice: "device",
				},
				refreshing: false,
				registration: null,
				updateExists: false,
				isRouterAlive: true,
				loginUser_id: "",
				showmenu: false,
				featurelist: [
					{
						hide: true,
						name: "功能選單",
						to: {
							name: "Portal",
						},
					},
					{
						name: "資產列表",
						shortname: "總表",
						icon: "store-alt",
						to: {
							name: "waitList",
						},
					},
					{
						name: "線上立案",
						shortname: "立案",
						icon: "plus-square",
						to: {
							name: "caseList",
						},
					},
					{
						name: "案件狀態",
						shortname: "狀態",
						icon: "briefcase",
						to: {
							name: "caseManage",
						},
					},
					{
						name: "案件紀錄",
						shortname: "紀錄",
						icon: "clipboard-list",
						to: {
							name: "closedList",
						},
					},

					{
						name: "更多功能",
						shortname: "功能",
						icon: "server",
						to: {
							name: "morefunc",
						},
					},
					{
						name: "聯絡夥伴",
						shortname: "聯絡",
						icon: "globe",
						to: {
							name: "online",
						},
					},
				],
				isalertshow: false,
				alertcount: "",
				status: {
					alertdata: [],
					newalert: [],
					readed: [],
					unread: [],
				},
				timer: 0,
				tensecond: 0,
				everymin: null,
				sec: null,
			};
		},
		created() {
			this.reload();
			this.refresh();
		},
		methods: {
			prev() {
				this.$router.go(-1);
			},
			openalert() {
				this.isalertshow = !this.isalertshow;
				if (this.isalertshow) {
					// this.sec = setTimeout(this.tensec, 10000);
				} else {
					clearTimeout(this.sec);
				}
			},
			refresh() {
				console.warn("每10秒" + moment().format("ss"));
				if (this.$store.state.member.login) {
					const ALT = {
						page: 0,
						pagenumber: 6,
						method: "",
						// spinner:false,
						cancelfunc: "AutoAlertmessage",
					};
					this.$store.dispatch("caseSort/AlertMessage", ALT).then((data) => {
						this.newalert = data.data.result.newmessage;
						setTimeout(this.refresh, 10000);
						if (data.data.result.newmessage.length > 0) {
							store.dispatch("Public/showToast", {
								title: "New Message",
								method: "warning",
								text: "您有新的通知，請儘速查看！",
							});
							store.dispatch("Public/Notification", {
								icon: "../public/img/androil-chrome-192*192.png",
								body: "您有新的通知",
								image: "",
								// data: {
								//   link: this.$router.push({
								//     name: 'Alert'
								//   })
								// },
								requireInteraction: true,
								actions: [],
							});
							// this.reload();
						}
					});
				}
			},
			tensec() {
				if (this.isalertshow) {
					this.isalertshow = false;
				}
			},
			ckshowmenu() {
				this.showmenu = this.showmenu ? false : true;
			},
			signout() {
				this.$bvModal
					.msgBoxConfirm("確定要登出嗎？", {
						size: "sm",
						okTitle: "確定",
						cancelTitle: "取消",
					})
					.then((value) => {
						if (value) {
							localStorage["token"] = "";
							this.$store.state.member.login = false;
							this.$router.push("/");
							store.dispatch("Public/showToast", {
								title: "訊息",
								method: "success",
								text: "成功登出！",
							});
						}
					});
			},
			showRefreshUI(e) {
				this.registration = e.detail;
				this.updateExists = true;
				if (this.updateExists) {
					this.refreshApp();
				}
			},
			refreshApp() {
				this.updateExists = false;
				if (!this.registration || !this.registration.waiting) {
					return;
				}
				this.registration.waiting.postMessage("skipWaiting");
			},
			reload() {
				const ALT = {
					page: 0,
					pagenumber: 5,
					method: "",
				};
				store.dispatch("caseSort/AlertMessage", ALT).then((data) => {
					this.status.alertdata = data.data.result.data;
					this.status.newalert = data.data.result.newmessage.length > 0 ? data.data.result.newmessage : [];
					const alertstatus = _.groupBy(data.data.result.data, "isAlert");
					this.status.readed = alertstatus[1] ? alertstatus[1] : [];
					this.status.unread = alertstatus[0] ? alertstatus[0] : [];
					this.alertcount = this.status.unread.length || "";
				});
			},
			goAllAlert() {
				this.$router.push({
					name: "Alert",
				});
				this.isalertshow = false;
				clearTimeout(this.sec);
			},
			gotocase(data) {
				this.$router.push({
					name: "caseDetail",
					params: {
						pro: data.linkuid,
					},
				});
				this.isalertshow = false;
				caseSort
					.setUpdate(
						"F_AlertList",
						data.uid,
						{
							isalert: "1",
						},
						false
					)
					.then((data) => {
						this.reload();
					});
			},
			gomanual() {
				this.$router.push({
					path: "/support/manual",
					hash: this.$route.meta.tomanual,
				});
			},
		},
		computed: {
			getbottomfeaturelist() {
				return this.featurelist.filter((item, keys) => {
					return !item.hide == true;
				});
			},
			openLoading: {
				get() {
					return this.$store.state.Public.loading;
				},
				set() {},
			},
		},
		watch: {
			"$route.params.pro"(newVal, oldVal) {
				if (newVal) {
					this.reload();
				}
			},
		},
		components: {
			loading,
		},
	};
</script>

<style lang="scss">
	#app {
		font-family: "微軟正黑體", Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		height: 100%;
		width: 100%;
		overflow: auto;
	}

	#mainbody {
		max-width: 768px;
	}

	#fakenav {
		padding: 10px;
		background: #eeeeee;
		letter-spacing: 1px;

		a {
			font-weight: bold;
			color: #2c3e50;
			padding: 0px 10px;

			&.router-link-exact-active {
				color: #42b983;
			}

			&:not(:first-child) {
				border-left: 1px solid #fff;
			}
		}
	}

	.hv-alert {
		&:hover {
			background-color: #f8f9fa;
		}
	}
</style>
