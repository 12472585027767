import _ from "lodash";
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        loading: false,
    },
    getters: {
        getname() {
            return (name) => {
                const tmp = (name || "").split("-");
                if (tmp.length > 1) {
                    tmp.splice(0, 1)
                    return tmp.join("-");
                } else {
                    return tmp.join();
                }
            }
        },
    },
    actions: {
        showToast({
            commit
        }, {
            title,
            method,
            text,
            time,
            toaster,
        }) {
            console.log(title);
            (new Vue).$bvToast.toast(text, {
                title: title,
                variant: method || "outline-info",
                toaster: toaster || "b-toaster-top-center",
                solid: true,
                autoHideDelay: time || 1300,
            })
        },
        openLoading({
            commit
        }, data) {
            commit("setLoading", true);
            return data;
        },
        closeLoading({
            commit
        }) {
            commit("setLoading", false);
        }
    },
    mutations: {
        setLoading(state, data) {
            state.loading = data;
        }
    }
}